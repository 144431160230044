import React from 'react';
import {
  Button,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Attachment, Report } from '@material-ui/icons';
import { darken } from 'polished';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
  btDocuments: {
    background: '#ffa726',
    color: '#fff',
    fontWeight: 'normal',
    borderRadius: '200px',
    fontSize: '14px',
    transition: 'background 0.2s',
    textTransform: 'unset',
    marginRight: '10px',
    '&:hover': {
      background: `${darken(0.1, '#ffa726')}`,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  dropZone: {
    border: `4px dashed #BDC0D4`,
    borderRadius: '10px',
    height: '200px',
    width: '400px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#F7F8FA',
    padding: '10px',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  btEnviar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  divFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
}));

function DocumentsPendingButton() {
  const contratos = useSelector((state) => state.contratos.data);
  const { documents } = useSelector((state) => state.documents);
  const classes = useStyles();
  const [hasDocumentsPending, setHasDocumentsPending] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contratosPending, setContratosPending] = React.useState([]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenContract = (event) => {
    const contratosVigentes = contratos.dados.map(
      (contrato) => contrato.contrato_id
    );

    if (contratosVigentes.length === 1) {
      if (contratos.dados[0].pendenteaceite) {
        history.push(`/pendente-aceite/${contratosVigentes[0]}/?action=2`);
        return;
      }
      history.push(`/detalhes-contrato/${contratosVigentes[0]}/?action=1`);
      return;
    }

    const contratosComPendencia = documents.map(
      (document) =>
        document.anexos.some((anexo) => !anexo.entregue) && document.contrato_id
    );

    if (contratosComPendencia.length === 1) {
      history.push(`/detalhes-contrato/${contratosVigentes[0]}/?action=1`);
    }

    setContratosPending(
      contratos.dados.map(
        (contrato) =>
          contratosComPendencia.includes(contrato.contrato_id) && {
            contrato_id: contrato.contrato_id,
            curso: contrato.curso,
            numeroContrato: contrato.numerocontrato,
            matricula: contrato.matricula,
            pendenteAceite: contrato.pendenteaceite,
          }
      )
    );

    setAnchorEl(event.currentTarget);
  };

  const onClickContrato = (contrato_id, pendenteAceite) => {
    if (pendenteAceite) {
      history.push(`/pendente-aceite/${contrato_id}/?action=2`);
      handleClose();
      return;
    }
    history.push(`/detalhes-contrato/${contrato_id}/?action=1`);
    handleClose();
  };

  const formatDate = (date) =>
    format(new Date(date), 'dd/MM/yyyy', {
      locale: ptBR,
    });

  React.useEffect(() => {
    if (documents.length > 0) {
      const hasPending = documents.some((document) =>
        document.anexos.some((anexo) => !anexo.entregue)
      );
      setHasDocumentsPending(hasPending);
    }
  }, [documents]); // eslint-disable-line

  if (documents.length === 0 || !hasDocumentsPending) {
    return null;
  }

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpenContract}
        variant="contained"
        className={classes.btDocuments}
        size="small"
        startIcon={<Report />}
      >
        <Hidden only="xs">
          <Typography component="span" variant="caption">
            Você tem documentos pendentes
          </Typography>
        </Hidden>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {contratosPending &&
          contratosPending.map((contrato) => (
            <MenuItem
              onClick={() =>
                onClickContrato(contrato.contrato_id, contrato.pendenteAceite)
              }
              key={contrato.contrato_id}
            >
              <ListItemIcon>
                <Attachment color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={contrato.curso}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Nº {contrato.numeroContrato}
                    </Typography>
                    {` - ${formatDate(contrato.matricula)}`}
                  </>
                }
              />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default DocumentsPendingButton;
